<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון פרטי דוח</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <h1>נא להזין כתובת חדשה</h1> -->
        <div class="DialogReportPaid__Component__Loading">
          <!-- <div v-if="isLoadingDriversList">
            <b-loading :is-full-page="false" v-model="isLoadingDriversList" :can-cancel="false" />
          </div> -->
          <b-field label="מספר דוח">
            <b-input v-model="data.ReportNumber"></b-input>
          </b-field>
          <b-field label="מספר רכב">
            <!-- <b-input v-model="data.Vehicle.Name"></b-input> -->
            <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="" :clearable="true" :filterable="true"
              :null-is-value="true" placeholder="הקלד לוחית רישוי" :options="[]" :fetch-options="onSearchVehicles"
              @onChange="onChange" :reduce="(item) => item" v-model="data.Vehicle.Name" />
          </b-field>
          <b-field label="עיריה">
            <!-- <b-input v-model="data.Municipality.Name"></b-input> -->
            <FieldInlineSelect :hide-hover="true" label="" :clearable="true" :filterable="true" :null-is-value="true"
              placeholder="בחר עיריה מהרשימה" :optionsAsync="getMunicipalities" @onChange="onChange"
              v-model="data.Municipality.Id" />
          </b-field>
          <b-field label="זמן קבלת הדוח">
            <b-datetimepicker locale="en-GB" :value="getDate" @input="setDate" editable
              :datepicker="{ monthNames, dayNames }" icon-prev="chevron-right" icon-next="chevron-left"
              icon="calendar-today" />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
// import ReportService from "@/services/ReportService";
import ReportService from "@/services/ReportService";
// import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import MunicipalitiesService from "@/services/MunicipalitiesService";
import { ToastProgrammatic as Toast } from "buefy";
import moment from "moment";
import store from '../store';
import VehicleService from "../services/VehicleService";

export default {
  name: "DialogUpdateProfile",
  props: ["reports", "entityId", "currentDriver", "onSuccess", "driverId", "store", "report"],
  components: {
    // FieldInlineDateTime
    FieldInlineSelect
  },
  computed: {
    items() {
      return this.reports ?? this.entityId;
    },
    profileId() {
      return store.state.profile.Id;
    },
    getDate() {
      return new Date(this.data.DateTaken);
    },
  },
  created() {
    this.data = { ...this.report, Vehicle: { ...this.report.Vehicle }, Municipality: { ...this.report.Municipality } };
    // ReportService.getDriverAdmin(this.driverId).then((r) => {
    //   // this.isLoadingDriversList = false;
    //   this.data = r.data;
    //   console.log(this.data);
    // });
    console.log(this.data);
  },
  data() {
    return {
      isLoading: false,
      // isLoadingDriversList: true,
      isDriverPaid: false,
      showAmount: false,
      amount: null,
      data: null,
      newReport: null,
      address: null,
      monthNames: [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
      ],
      dayNames: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    };
  },
  methods: {
    onChange() { },
    getMunicipalities() {
      return MunicipalitiesService.get;
    },
    onSearchVehicles({ search, loading, setData, setValue }) {
      if (search.length > 4) {
        loading(true);
        return (
          VehicleService.searchAdminPlateNumber(search)
        )
          .then((r) => {
            if (!r.data.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    setDate(e) {
      this.data.DateTaken = e;
      console.log(this.data.DateTaken);
    },
    setSelected(value) {
      this.driverId = value;
    },
    async save() {
      this.isLoading = true;
      const newModel = { ...this.data };
      newModel.DateTaken = moment(newModel.DateTaken).format("YYYY-MM-DDTHH:mm");
      if (newModel.Vehicle.Name.Text) {
        newModel.Vehicle = { Id: newModel.Vehicle.Name.Value, Name: newModel.Vehicle.Name.Text };
      } else {
        newModel.Vehicle = { Id: this.data.Vehicle.Id, Name: this.data.Vehicle.Name };
      }
      const res = await MunicipalitiesService.getMunicipality(this.data.Municipality.Id);
      newModel.Municipality = { Id: res.data.Id, Name: res.data.Name };
      ReportService.updateAdmin(this.data.Id, {
        ...this.data,
        ...newModel,
      })
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          let message = '';
          switch (err.response?.data?.ErrorCode) {
            case 19:
              message = 'לא ניתן לעדכן את השדות המוגנים של הדוח מכיון שהוא כבר שולם או אומת מול העיריה.';
              break;

            default:
              message = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.";
              break;
          }
          Toast.open({
            type: "is-danger",
            message,
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
body>div.modal.is-active.entity-modal>div.animation-content.modal-content>div {
  margin-bottom: 260px;
}

body>div.modal.is-active.entity-modal>div.animation-content.modal-content>div>div>section>div>div:nth-child(4)>div>div>div.dropdown-menu>div>div>footer>nav>div>div>div>div>div>div>div>div>div {
  direction: ltr !important;
}

body>div.modal.is-active.entity-modal>div.animation-content.modal-content>div>div>section>div>div:nth-child(3)>div>label>div.v-select.vs--single.vs--searchable,
body>div.modal.is-active.entity-modal>div.animation-content.modal-content>div>div>section>div>div:nth-child(2)>div>label>div.v-select.vs--single.vs--searchable {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border-color: #dbdbdb;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
}
</style>
